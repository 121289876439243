import React, { useState } from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/products.css"

import img1 from "../images/domek-ogrodowy-4-m-na-4-m-45mm/0001.webp"
import img2 from "../images/domek-ogrodowy-4-m-na-4-m-45mm/0002.webp"
import img3 from "../images/domek-ogrodowy-4-m-na-4-m-45mm/0003.webp"
import img4 from "../images/domek-ogrodowy-4-m-na-4-m-45mm/0004.webp"
import img5 from "../images/domek-ogrodowy-4-m-na-4-m-45mm/0005.webp"
import img6 from "../images/domek-ogrodowy-4-m-na-4-m-45mm/0006.webp"
import img7 from "../images/domek-ogrodowy-4-m-na-4-m-45mm/0007.webp"
import img8 from "../images/domek-ogrodowy-4-m-na-4-m-45mm/0008.webp"

import raty from "../images/raty-banner.jpg"

import ImageGallery from "react-image-gallery"

const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  },
  {
    original: img6,
    thumbnail: img6,
  },
  {
    original: img7,
    thumbnail: img7,
  },
  {
    original: img8,
    thumbnail: img8,
  },
]

const ProductView14 = () => {
  const [price, setPrice] = useState(12999)
  const [montage, setMontage] = useState("false")
  const [gont, setGont] = useState("false")
  const [painting, setPainting] = useState("false")
  const [floor, setFloor] = useState("false")

  const montageChange = (e) => {
    setMontage(e)
    montage !== "true" ? setPrice(price + 2000) : setPrice(price - 2000)
  }

  const gontChange = (e) => {
    setGont(e)
    gont !== "true" ? setPrice(price + 1900) : setPrice(price - 1900)
  }

  const paintChange = (e) => {
    setPainting(e)
    painting !== "true" ? setPrice(price + 700) : setPrice(price - 700)
  }

  const floorChange = (e) => {
    setFloor(e)
    floor !== "true" ? setPrice(price + 1500) : setPrice(price - 1500)
  }

  return (
    <Layout>
      <SEO
        title="Domek letniskowy"
        description={SEO.description}
        urlName="domek-ogrodowy-4-m-na-4-m-balik-45mm"
      />
      <div className="banner">
        <div className="bannerPlate">
          <p>Domek Ogrodowy</p>
        </div>
      </div>
      <div className="productViewPlate">
        <div className="gallery">
          <ImageGallery slideInterval="2000" items={images} />
        </div>
        <div className="productDescription">
          <div id="prodTop"></div>
          <div id="prodLeft"></div>
          <div id="prodBottom"></div>
          <div id="prodRight"></div>
          <div id="productTitle">Domek Letniskowy 4mx4m - balik 45mm</div>
          <div id="productPrice">{price}zł</div>
          <div className="productOption">
            <h3>Dodatkowe opcje:</h3>
            <h4>Montaż:</h4>
            <select
              value={montage}
              onChange={(val) => montageChange(val.target.value)}
            >
              <option value="false">Nie</option>
              <option value="true">Tak</option>
            </select>
            <h4>Gont:</h4>
            <select
              value={gont}
              onChange={(val) => gontChange(val.target.value)}
            >
              <option value="false">Nie</option>
              <option value="true">Tak</option>
            </select>
            <h4>Malowanie(bezbarwny impregnat):</h4>
            <select
              value={painting}
              onChange={(val) => paintChange(val.target.value)}
            >
              <option value="false">Nie</option>
              <option value="true">Tak</option>
            </select>
            <h4>Podłoga:</h4>
            <select
              value={floor}
              onChange={(val) => floorChange(val.target.value)}
            >
              <option value="false">Nie</option>
              <option value="true">Tak</option>
            </select>
            <div className="productDescriptionText">
              <h3>Opis</h3>
              <p>
                Drewniany domek letniskowy o powierzchni 16m<sup>2</sup> to prosta
                i stabilna konstrukcja, która sprawdzi się jako stylowe
                wyposażenie ogrodu jak i schowek na narzędzia czy inne potrzebne
                rzeczy do naszego ogrodu. Wymiary zewnętrzne domku wynoszą 400cm
                x 400cm a grubość balika to aż 45mm! Istnieje możliwość zakupu produktu na raty. Domek
                ogrodowy drewhaus to gwarancja jakości na długie lata!
                <br />
              </p>
              <h3>Dane techniczne:</h3>
              <ul>
                <li>Materiał: suszone drewno świerkowe</li>
                <li>Wymiar zewnętrzny całkowity: 400cm x 400cm </li>
                <li>Wymiar zewnętrzny ścian: 381cm x 381cm</li>
                <li>Wymiar wewnętrzny ścian: 375cm x 375cm</li>
                <li>Wysokość ściany: 195cm</li>
                <li>Wysokość do szczytu: 235cm</li>
                <li>
                  Powierzchnia dachu: 18,50m<sup>2</sup>
                </li>
                <li>Grubość ścian: aż 45mm</li>
                <li>Grubość deski dachowej: 18mm</li>
                <li>Drzwi: oszklone 140cm x 185cm</li>
              </ul>
              <a href="https://epos.inbank.pl/pl/drewhaus">
                <img
                  src={raty}
                  className="creditBanner"
                  alt="domek-ogrodowy-raty"
                />
              </a>
              <p>
                <b>
                  Domki ogrodowe możesz obejrzeć w Gnieźnie! Zajrzyj do
                  zakładki&nbsp;
                  <Link to="/contact" style={{ color: "#765F52" }}>
                    kontakt
                  </Link>{" "}
                  i sprawdź gdzie nas znajdziesz!
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductView14
